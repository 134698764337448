



































































































































































































































































import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";
import countriesList from "@/assets/data/countries.json";
import prefixesList from "@/assets/data/prefixes.json";

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        type: "",
        firstName: "",
        lastName: "",
        gender: "",
        languageVersion: "",
        category: "",
        photo: "",
        degree: "",
        specialization: [],
        description: "",
        note: "",
        notePriority: "",
        sponsor: "",
        status: "",
        group: [],
        studentNumber: "",
        phonePrefix: "",
        phone: "",
        email: "",
        country: "",
        city: "",
        postalCode: "",
        street: "",
        institutionName: "",
        institutionPosition: "",
        institutionCity: "",
        institutionPostalCode: "",
        institutionStreet: "",
        marketingConsent: false,
        emailOffersConsent: false,
        phoneOffersConsent: false,
        termsConsent: false,
      }),
    },
  },

  setup(props, { root }) {
    const state = reactive({
      degrees: [],
      eventDegrees: [],
      table: false,
      loading: false,
      loaded: false,
      groups: [],
      specialization: [],
      eventType: "",
      language: root.$store.getters["language/getLanguage"],
      primaryConsents: [],
      secondaryConsents: [],
      consents: [],
      website: "",
    });

    const {
      participantStatusItems,
      languageItemsCurrency,
      salutationItems,
    } = useSelectItems({ root });

    const fetchDegree = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("degree")
        .then(({ data: { degrees } }) => {
          state.degrees = degrees;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.degrees = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchDegree);

    const fetchSpecialization = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("specialization")
        .then(({ data: { specializations } }) => {
          state.specialization = specializations;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.specialization = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchSpecialization);

    const fetchGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups } }) => {
          state.groups = groups;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.groups = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchGroups);

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.eventDegrees = event.degrees ?? [];
          state.eventType = event.type;
          state.primaryConsents = event.registrationFormTemplate?.groups
            .filter((i: any) => i.type === "consents")
            .map((el: any) => el.fields)
            .flat();
          state.secondaryConsents = event.secondaryRegistrationFormTemplate?.groups.filter(
            (i: any) => i.type === "consents"
          );
          state.consents =
            props.value.languageVersion === "polish"
              ? state.primaryConsents
              : state.secondaryConsents;
          state.website = event.wordpressWebsite.domain;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchEvent);

    const noteItems = ["niski", "wysoki"];

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          root.$tc("layout.misc.validZipCode"),
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          root.$tc("layout.misc.validMobilePhone"),
      ],
    };

    const getConsents = () => {
      if (props.value.languageVersion === "polish") {
        state.consents = state.primaryConsents;
      } else state.consents = state.secondaryConsents;
    };

    watch(() => props.value.languageVersion, getConsents);

    const replaceVariables = (str: string) => {
      const variables: { [key: string]: string } = {
        "{website}": state.website ? `https://${state.website}` : "",
      };
      for (const variable of Object.keys(variables)) {
        str = str?.replaceAll(variable, variables[variable]) || "";
      }
      return str;
    };

    return {
      state,
      participantStatusItems,
      languageItemsCurrency,
      noteItems,
      salutationItems,
      rules,
      countriesList,
      prefixesList,
      replaceVariables,
    };
  },
});
